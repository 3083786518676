import React, { useEffect } from 'react'
import About from '../About/About'
import Contact from '../Contact/Contact'
import Experiences from '../Experiences/Experiences'
import Dots from '../layout/dots'
import Footer from '../layout/footer'
import Header from '../layout/header'
import Skills from '../Skills/Skills'
import Work from '../Work/Work'

import 'react-appear-on-scroll/dist/index.css';

export default function Client() {
    return (
        <div id="main-container">
            <Dots />
            <Header />
            <About />
            <Skills />
            <Experiences />
            <Work />
            <Contact />
            <Footer />
        </div>
    )
}
