import axios from "axios";
import Swal from "sweetalert2";
import { baseurl } from "./env";

export async function getData({url, callback, data, beforeSend}){
    data ||= {};
    beforeSend ||= ()=>{};
    const _token = localStorage.getItem('_token');
    let config = {
      headers : {
        Authorization : null
      }
    }
    if (_token) config.headers.Authorization = "Bearer "+_token;

    axios.interceptors.request.use(config => {
      beforeSend(config);
      return config;
    });
  
    await axios.get(baseurl+url, {params: data, ...config})
    .then(function (response) {
      // handle success
      callback(response.data, response);
    })
    .catch(function (error) {
      // handle error
      Swal.fire(
          'Erreur!',
          'Nous n\'avions pas pu récuperer les données!',
          'error'
      )
      callback(null);
    })
    .then(function () {
      // always executed
    });
}

export function postData({url, callback, data, beforeSend, method = 'POST'}){
    data ||= {};
    beforeSend ||= ()=>{};
    const _token = localStorage.getItem('_token');
    const formData = new FormData();

    for (const key in data) {
      if (Object.hasOwnProperty.call(data, key)) {
        formData.append(key,data[key])
      }
    }

    formData.append('_method',method.toUpperCase())
    
    let config = {
      headers : {
        Authorization : null,
        'content-type': 'multipart/form-data'
      }
    }
    if (_token) config.headers.Authorization = "Bearer "+_token;

    axios.interceptors.request.use(config => {
      beforeSend(config);
      return config;
    });

    axios.post(baseurl + url, formData, config)
    .then(function (response) {
      // handle success
      callback(response.data, response);
    })
    .catch(function (error) {
      // handle error
      Swal.fire(
        'Erreur!',
        'Les données ne peuvent être envoyées!',
        'error'
      )
      callback(null);
    })
    .then(function () {
      // always executed
    });
}