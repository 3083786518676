import React from 'react'
import { Button, Card, Table } from 'react-bootstrap';
import Swal from 'sweetalert2';
import PropTypes from 'prop-types';

export default function TableComponent({ columns, datas, title, id, handleOpen, remove, edit }) {
    const onRemove = (id)=>{
        Swal.fire({
            title: 'Voulez-vous vraiment supprimer cet élément?',
            showDenyButton: false,
            showCancelButton: true,
            confirmButtonText: 'Supprimer',
            cancelButtonText: `Annuler`,
        }).then((result) => {
            if (result.isConfirmed) {
                remove(id);
            }
        })
    } 
    return (
        <section id={id} className='mb-4'>
            <Card className='p-3'>
                <div className='container d-flex mb-3' style={{ justifyContent : "space-between", alignItems : "center" }}>
                    <h6>{title}</h6>
                    <Button variant="primary" onClick={handleOpen}>Ajouter <i className='fa fa-plus'></i></Button>
                </div>
                <div className='container'>
                    <Table responsive striped>
                        <thead>
                            <tr>
                                <th>#</th>
                                {columns.map((column, index) => (
                                    <th key={index}>{column.title}</th>
                                ))}
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                        {
                            datas.map((data, index) => (
                                <tr key={index}>
                                    <td>{index + 1}</td>
                                    {columns.map((column, index) => (
                                        <td key={index} style={column.field==="description"?{ minWidth: "250px" }:{}}>{data[column.field]}</td>
                                    ))}
                                    <td style={{ width : "175px" }}>
                                        <Button variant="info" onClick={() => edit(data)}><i className='fa fa-edit'></i></Button>{' '}
                                        <Button variant="danger" onClick={() => onRemove(data.id)}><i className='fa fa-trash'></i></Button>
                                    </td>
                                </tr>
                            ))
                        }
                        </tbody>
                    </Table>
                </div>
            </Card>
        </section>
    )
}

TableComponent.propTypes = {
    datas: PropTypes.array
};