import React, { useEffect, useState } from 'react';

import lines from "../../assets/images/lines.svg";
import { AppearingContainer } from 'react-appear-on-scroll';
import { getProjects } from '../../requests/works';
import { file_baseurl } from '../../api/env';

export default function Work() {
    const [datas, setdatas] = useState([]);
    
    useEffect(() => {
        getProjects().then(res=>{
            if (res) {
                setdatas(res);
            }
        })
    }, []);

    return (
        <section id="portfolio" className="site-section section-portfolio">
            <div className="container">
                <div className="text-center mb-5">
                    <h2>My recent Works</h2>
                    <img src={lines} className="img-lines" alt="lines" />
                </div>
                <div className='container'>
                    <div className="row">
                        {
                            datas.map((item, index) => (<div className="col-lg-4 col-md-6 col-sm-12" key={index}>
                                <div className="portfolio-item">
                                    <img src={file_baseurl+item.image} className="img-res" alt="" />
                                    <div className="portfolio-item-info">
                                        <h4>{item.title}</h4>
                                        <a href="#" style={{ marginRight : "7px" }}><span className="fa fa-eye"></span></a>
                                        <a href={item.link}><span className="fa fa-link"></span></a>
                                    </div>
                                </div>
                            </div>))
                        }
                    </div>
                </div>
            </div>
        </section>
    )
}
