import React, { Fragment, useEffect, useState } from 'react';
import Swal from 'sweetalert2';
import { getEducations, removeEducation } from '../../../requests/educations';
import StudyModal from '../modals/study';
import TableComponent from './table';

const COLUMNS = [
    { title : "Annee", field : "annee" },
    { title : "Filiere", field : "filiere" },
    { title : "Ecole", field : "school" },
    { title : "Description", field : "description" },
];

export default function Etudes() {
    const [educations, seteducations] = useState([]);
    const [openModal, setopenModal] = useState(false);
    const [current, setcurrent] = useState(null);

    const handleOpenModal = () => setopenModal(true);
    const handleCloseModal = () => {
        setcurrent(null);
        setopenModal(false);
    }

    useEffect(() => {
        getStudies();
    }, []);

    const getStudies = () => getEducations().then(res=>seteducations(res));

    const removeStudies = async (id) => {
        const r = removeEducation(id);
        if (r) {
            getStudies();
            Swal.fire({
                text: 'Élément supprimé',
                icon: 'success',
                confirmButtonText: 'Fermer',
            })
        }
    }
    
    const editStudy = (data) => {
        setcurrent(data);
        handleOpenModal();
    }

    return (
        <Fragment>
            <StudyModal 
                show={openModal}
                handleClose={handleCloseModal}
                current={current}
                getExps={getStudies}
            />
            <TableComponent 
                handleOpen={handleOpenModal}
                columns={COLUMNS}
                datas={educations}
                id={"etudes"}
                title="Études"
                remove={removeStudies}
                edit={editStudy}
            />
        </Fragment>
    )
}
