import React, { useEffect, useRef } from 'react';

const FPS = 60;
var x = 40;

export default function Dots() {
    const canvas = useRef(null);

    const draw = function (ctx, stars) {
        if (ctx) {
            ctx.clearRect(0,0,canvas.width,canvas.height);
        
            // ctx.globalCompositeOperation = "lighter";
            
            for (var i = 0, x = stars.length; i < x; i++) {
                var s = stars[i];
                ctx.fillStyle = "#fff4";
                ctx.beginPath();
                ctx.arc(s.x, s.y, s.radius, 0, 2 * Math.PI);
                ctx.fill();
                ctx.fillStyle = 'white';
                ctx.stroke();
            }
            
            ctx.beginPath();
            for (let i = 0, x = stars.length; i < x; i++) {
                var starI = stars[i];
                ctx.moveTo(starI.x,starI.y); 
                for (var j = 0, r = stars.length; j < r; j++) {
                    var starII = stars[j];
                        if(distance(starI, starII) < 150) {
                        //ctx.globalAlpha = (1 / 150 * distance(starI, starII).toFixed(1));
                        ctx.lineTo(starII.x,starII.y); 
                    }
                }
            }
            ctx.lineWidth = 0.05;
            ctx.strokeStyle = '#fff7';
            ctx.stroke();
        }
    }

    const distance = function ( point1, point2 ){
        var xs = 0;
        var ys = 0;
       
        xs = point2.x - point1.x;
        xs = xs * xs;
       
        ys = point2.y - point1.y;
        ys = ys * ys;
       
        return Math.sqrt( xs + ys );
    }

    useEffect(() => {
        const ctx = canvas.current.getContext('2d');
        let stars = [];
        let animationFrameId;

        canvas.width = window.innerWidth;
        canvas.height = window.innerHeight;

        for (let i = 0; i < x; i++) {
            stars.push({
                x: Math.random() * canvas.width,
                y: Math.random() * canvas.height,
                radius: Math.random() * 1 + 1,
                vx: Math.floor(Math.random() * 50) - 25,
                vy: Math.floor(Math.random() * 50) - 25
            });
        }        
          
        const update = function(stars) {
            for (var i = 0, x = stars.length; i < x; i++) {
                var s = stars[i];
                
                s.x += s.vx / FPS;
                s.y += s.vy / FPS;
                
                if (s.x < 0 || s.x > canvas.width) s.vx = -s.vx;
                if (s.y < 0 || s.y > canvas.height) s.vy = -s.vy;
            }
            return stars
        }
    
        const tick = function () {
            draw(ctx, stars);
            stars=update(stars);
            animationFrameId = window.requestAnimationFrame(tick);
        }

        tick();

        return () => {
            window.cancelAnimationFrame(animationFrameId)
        }
    }, [])
    
    return (
        <div id='canvasBG'>
            <canvas id='canvas' ref={canvas} width={window.innerWidth} height={window.innerHeight}></canvas>
        </div>
    )
}
