import React, { Suspense, useState } from 'react';
import { Link, Outlet } from 'react-router-dom';
import Sidebar from '../layout/sidebar';
import SidebarToogle from '../layout/sidebarToogle';
import useAuth from '../../hooks/useAuth';

import "../../assets/scss/admin.scss";

export default function Admin() {
    const [openSidebar, setopenSidebar] = useState(true);
    const auth = useAuth();

    const logout = e=>{
        e.preventDefault();
        auth.logout();
    }

    return (
        <main id="admin">
            <Sidebar open={openSidebar} handleOpen={setopenSidebar} />
            <div className={`main-container ${openSidebar ? "" : 'full'}`}>
                <Suspense>
                    <div className='sidebar-wrapper' onClick={() => setopenSidebar(!openSidebar)}></div>
                    <SidebarToogle 
                        open={openSidebar} handleOpen={setopenSidebar}
                    />
                    <nav>
                        <ul className='nav-list-right'>
                            <li>
                                <Link to='/' className='p-2'>
                                    <i className='fa fa-home-user'></i>
                                </Link>
                            </li>
                        </ul>
                        <a href='/#' style={{ textDecoration : "none" }} className='text-dark p-2' onClick={logout}>
                            <i className='fa fa-sign-out-alt'></i> Se déconnecter
                        </a>
                    </nav>
                    <div className='main-content'>
                        <Outlet />
                    </div>
                </Suspense>
            </div>
        </main>
    )
}
