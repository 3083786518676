import { getData, postData } from "../api/request";

export async function getExperiences(){
    let result = [];

    await getData({
        url : "/experiences",
        callback : response=>{
            if (response) {
                result = response
            }
        }
    })

    return result;
}

export async function addExperience({annee, company, job, description}){
    let result = [];

    await postData({
        url : "/experiences",
        data: {annee, company, job, description},
        callback : response=>{
            result = response;
        }
    })

    return result;
}

export async function editExperience({id, annee, company, job, description}){
    let result = [];

    await postData({
        method : 'put',
        url : `/experiences/${id}`,
        data: {annee, company, job, description},
        callback : response=>{
            result = response;
        }
    })

    return result;
}

export async function removeExperience(id){
    let result = [];

    await postData({
        method : 'delete',
        url : `/experiences/${id}`,
        data: {id},
        callback : response=>{
            result = response;
        }
    })

    return result;
}