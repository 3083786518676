import React from 'react'
import Etudes from './edit-add/etudes'
import Experiences from './edit-add/experiences'
import Projects from './edit-add/projects';
import Skills from './edit-add/skills'

export default function Edit() {
    return (
        <div>
            <h3 className='mb-4'>Modification</h3>
            <Skills />
            <Experiences />
            <Etudes />
            <Projects />
        </div>
    )
}
