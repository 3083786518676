import { getData, postData } from "../api/request";

export async function getProjects(){
    let result = [];

    await getData({
        url : "/works",
        callback : response=>{
            if (response) {
                result = response
            }
        }
    })

    return result;
}

export async function addProject({image, title, link}){
    let result = [];

    await postData({
        url : "/works",
        data: {image, title, link},
        callback : response=>{
            result = response;
        }
    })

    return result;
}

export async function editProject({id, image, title, link}){
    let result = [];

    await postData({
        method : 'put',
        url : `/works/${id}`,
        data: { image, title, link},
        callback : response=>{
            result = response;
        }
    })

    return result;
}

export async function removeProject(id){
    let result = [];

    await postData({
        method : 'delete',
        url : `/works/${id}`,
        data: {id},
        callback : response=>{
            result = response;
        }
    })

    return result;
}