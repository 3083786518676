import { postData } from "../api/request";

export async function sendMail({name, email, subject, message}){
    let result = [];

    await postData({
        url : "/contact",
        data: {name, email, subject, message},
        callback : response=>{
            result = response;
        }
    })

    return result;
}