import React, { Component } from 'react'

import image from '../../assets/images/contact.png';
import lines from "../../assets/images/lines.svg";
import { sendMail } from '../../requests/contact';

export default class Contact extends Component {
    constructor(props) {
        super(props);

        this.state = {
            name: "",
            email: "",
            subject: "",
            message: "",
        }

        this.sendForm= this.sendForm.bind(this);
        this.handleInput= this.handleInput.bind(this);
    }

    handleInput(e){
        const target = e.currentTarget;
        this.setState({
            [target.name]: target.value
        });
    }

    sendForm(e){
        e.preventDefault();

        sendMail(this.state);
    }

    render() {
        return (
            <section id="contact">
                <div className="container">
                    <div className='text-center mb-3'>
                        <h2>Contact</h2>
                        <img src={lines} className="img-lines" alt="lines" />
                    </div>
                    <div className="row justify-content-center">
                        <div className="col-md-10">
                        <div className="row justify-content-center">
                            <div className="col-md-6 p-2">
                                <h3 className="heading mb-4">Let's talk about everything!</h3>
                                <p>I'm open for any suggestion or just to have a chat</p>
                                <p>
                                    <span style={styles.span}><i className='fa fa-phone' style={{ width: "30px" }}></i>+261 34 55 080 66</span>
                                    <span style={styles.span}><i className='far fa-envelope' style={{ width: "30px" }}></i>syllamiran@hackory.mg</span>
                                    <span style={styles.span}><i className='fa fa-map-marker-alt' style={{ width: "30px" }}></i>IVA 13 ARIVONIMAMO NORD</span>
                                </p>
                                <p><img src={image} alt="COntact" className="img-fluid" /></p>
                            </div>
                            <div className="col-md-6 p-2">
                                <form className="mb-5" method="post" id="contactForm" name="contactForm" onSubmit={this.sendForm}>
                                    <div className="row">
                                    <div className="col-md-12 form-group mb-3">
                                        <input type="text" className="form-control" name="name" id="name" placeholder="Your name" onChange={this.handleInput} />
                                    </div>
                                    </div>
                                    <div className="row">
                                    <div className="col-md-12 form-group mb-3">
                                        <input type="text" className="form-control" name="email" id="email" placeholder="Email" onChange={this.handleInput} />
                                    </div>
                                    </div>
                                    <div className="row">
                                    <div className="col-md-12 form-group mb-3">
                                        <input type="text" className="form-control" name="subject" id="subject" placeholder="Subject" onChange={this.handleInput} />
                                    </div>
                                    </div>
                                    <div className="row">
                                    <div className="col-md-12 form-group mb-3">
                                        <textarea className="form-control" name="message" id="message" cols="30" rows="7" placeholder="Write your message" onChange={this.handleInput}></textarea>
                                    </div>
                                    </div>  
                                    <div className="row">
                                    <div className="col-12">
                                        <input type="submit" value="Send Message" className="btn btn-primary rounded-0 py-2 px-4" />
                                    <span className="submitting"></span>
                                    </div>
                                    </div>
                                </form>

                                <div id="form-message-warning mt-4"></div> 
                                <div id="form-message-success">
                                    Your message was sent, thank you!
                                </div>
                            </div>
                        </div>
                        </div>
                    </div>
                </div>

            </section>
        )
    }
}

const styles = {
    span:{
        display: "block",
        marginBottom: "10px"
    }
}