import React, { Fragment } from 'react';
import { NavLink } from 'react-router-dom';

import logo from "../../assets/images/bouclier.png";
import { links } from '../../routes/links';
import Accordion from './accordion';

const SectionItem = ({ link }) => {
    return <Fragment>
        <li className='link-section-title'>{ link.title }</li>
        <ul className='link-section link-section-children'>
        {
            link.children.map((child, i) => (
                <LinkItem key={i} link={child} />
            ))
        }
        </ul>
    </Fragment>
}

const LinkItem = ({link}) => {
    if (link.type === "section") {
        return <SectionItem link={link} />
    } else if(link.type === "link") {
        return <li className='link-item'>
            <NavLink to={"/admin/"+link.path} className="navlink">
                <i className={`link-icon ${link.icon}` }></i>
                <span className='link-title'>{ link.title }</span>
            </NavLink>
        </li>
    } else {
        return <li className='link-item'>
            <Accordion
                icon={link.icon}
                title={link.title}
                link={"/admin/"+link.path}
            >
                <ul className='link-section link-section-children'>
                {
                    link.children.map((child, i) => (
                        <LinkItem key={i} link={child} />
                    ))
                }
                </ul>
            </Accordion>
        </li> 
    }
}

export default function Sidebar({ open, handleOpen }) {
    return (
        <div className={`sidebar ${open ? 'open' : 'collapsed'}`}>
            <div className='logo-container'>
                <h6 style={{ color : "white" }}>
                    <img src={logo} alt="Avatar" />
                    <span>Admin</span>
                </h6>
                <button className='menu-bars' type='button' onClick={() => handleOpen(!open)}>
                    <i className={`fa fa-${open ? "bars" : "times"}`}></i>
                </button>
            </div>
            <div>
                <ul className='link-section'>
                {
                    links.map((route, index) => (
                        <LinkItem link={route} key={index} />
                    ))
                }
                </ul>
            </div>
        </div>
    )
}
