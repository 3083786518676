import React, { useEffect, useState } from 'react';

import lines from "../../assets/images/lines.svg";
import { getCompetences } from '../../requests/competences';

export default function Skills() {
    const [datas, setdatas] = useState([]);
    
    useEffect(() => {
        getCompetences().then(res=>{
            if (res) {
                setdatas(res);
            }
        })
    }, [])
    

    return (
        <section className="section my-services" data-section="section2" id='skills'>
            <div className="container">
                <div className="section-heading">
                    <div className='text-center'>
                        <h2>What I’m good at?</h2>
                        <img src={lines} className="img-lines" alt="lines" />
                    </div>
                    <div className="line-dec"></div>
                    <span className='text-center d-block'>Since I started Devops, I respect standards and best practices in development as well as honest and transparent communication to my clients. The goal is to achieve a reliable, fast, optimized and secure product.</span>
                </div>
                <div className='container mt-4'>
                    <div className="row">
                    {
                        datas.map((item, index) => (
                            
                                <div className="col-md-6" key={index}>
                                    <div className="service-item">
                                        <div className={`${item.icon} service-icon`}></div>
                                        <h4>{item.title}</h4>
                                        <p>{item.description}</p>
                                    </div>
                                </div>
                        ))
                    }
                    </div>
                </div>
            </div>
        </section>
    )
}
