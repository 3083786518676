import { getData, postData } from "../api/request";

export async function getEducations(){
    let result = [];

    await getData({
        url : "/educations",
        callback : response=>{
            if (response) {
                result = response
            }
        }
    })

    return result;
}

export async function addEducation({annee, filiere, school, description}){
    let result = [];

    await postData({
        url : "/educations",
        data: {annee, filiere, school, description},
        callback : response=>{
            result = response;
        }
    })

    return result;
}

export async function editEducation({id, annee, filiere, school, description}){
    let result = [];

    await postData({
        method : 'put',
        url : `/educations/${id}`,
        data: {annee, filiere, school, description},
        callback : response=>{
            result = response;
        }
    })

    return result;
}

export async function removeEducation(id){
    let result = [];

    await postData({
        method : 'delete',
        url : `/educations/${id}`,
        data: {id},
        callback : response=>{
            result = response;
        }
    })

    return result;
}