import React, { Component } from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import Swal from 'sweetalert2';
import { addProject, editProject } from '../../../requests/works';

const INITIALSTATE = {
    image : "", title : "", link: ""
};

export default class WorkModal extends Component {
    constructor(props) {
        super(props);

        this.state = INITIALSTATE;

        this.handleInput = this.handleInput.bind(this);
        this.add = this.add.bind(this);
    }

    handleInput(e){
        const target = e.currentTarget;

        this.setState({
            [target.name] : target.name==="image"? target.files[0]: target.value
        })
    }

    async add(){
        let send;
        if(this.props.current){
            send = await editProject(this.state);
        }else{
            send = await addProject(this.state);
        }
        if (send) {
            this.props.getExps();
            if (send.error) {
                Swal.fire({
                    title: 'Erreur!',
                    text: 'Echec de l\'enregistrement',
                    icon: 'error',
                    confirmButtonText: 'Fermer',
                })
            }
            else{
                this.setState(INITIALSTATE);
                Swal.fire({
                    text: 'Requête éffectué',
                    icon: 'success',
                    confirmButtonText: 'Fermer'
                })
            }
        }
        this.props.handleClose();
    }

    componentDidUpdate(prevProps){
        if (prevProps.current !== this.props.current) {
            if (this.props.current) {
                this.setState(this.props.current);
            }
            else{
                this.setState(INITIALSTATE);
            }
        }
    }

    render() {
        const { show, handleClose, current } = this.props;
        return (
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Étude</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={this.add}>
                        <Form.Group className="mb-3" controlId="Form.ControlInput1">
                            <Form.Label>Image</Form.Label>
                            <Form.Control type="file" name='image' onChange={this.handleInput} accept="image/*" />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="Form.ControlInput2">
                            <Form.Label>Titre</Form.Label>
                            <Form.Control type="text" name='title' placeholder="Titre" onChange={this.handleInput} value={this.state.title} />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="Form.ControlInput3">
                            <Form.Label>Lien</Form.Label>
                            <Form.Control type="text" name='link' placeholder="Lien" onChange={this.handleInput} value={this.state.link} />
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Annuler
                    </Button>
                    <Button variant="info" onClick={this.add}>
                        { current?"Modifier" :"Ajouter"}
                    </Button>
                </Modal.Footer>
            </Modal>
        )
    }
}
