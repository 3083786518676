import React, { useState } from 'react';
import { Link } from "react-router-dom";

import '../../assets/scss/login.scss';

import image from '../../assets/images/login.png'
import useAuth from '../../hooks/useAuth';

export default function Login() {
    const [loading , setLoading] = useState(true)
    const [email, setemail] = useState('');
    const [password, setPassword] = useState('');

    const auth = useAuth();
    const send = async function () {
        if (loading) {
            setLoading(false)
            const load = await auth.login(email, password);
            if (load) setLoading(load)
        }
        return null;
    }

    const handleEmail = (e) => {
        const target = e.currentTarget
        setemail(target?.value)
    }

    const handlePassword = (e) => {
        const target = e.currentTarget
        setPassword(target?.value)
    }

    return (
        <div id='login'>
            <div className="limiter">
                <div className="container-login100">
                    <div className="wrap-login100">
                        <div className="login100-pic js-tilt" data-tilt>
                            <img src={image} alt="IMG" />
                        </div>

                        <form className="login100-form validate-form">
                            <span className="login100-form-title">
                                Member Login
                            </span>

                            <div className="wrap-input100 validate-input" data-validate="Valid email is required: ex@abc.xyz">
                                <input className="input100" type="text" name="email" placeholder="Email" onChange={handleEmail} />
                                <span className="focus-input100"></span>
                                <span className="symbol-input100">
                                    <i className="fa fa-envelope" aria-hidden="true"></i>
                                </span>
                            </div>

                            <div className="wrap-input100 validate-input" data-validate="Password is required">
                                <input className="input100" type="password" name="pass" placeholder="Password" onChange={handlePassword} />
                                <span className="focus-input100"></span>
                                <span className="symbol-input100">
                                    <i className="fa fa-lock" aria-hidden="true"></i>
                                </span>
                            </div>

                            <div className="container-login100-form-btn">
                                <button className="login100-form-btn" type='button' onClick={send} disabled={!loading}>
                                    Login
                                </button>
                            </div>

                            <div className="text-center p-t-12" style={{ fontWeight: "bold" }}>
                                <span className="txt1">Forgot </span>
                                <a className="txt2" href="#"> Password?</a>
                            </div>

                            <div className="text-center p-t-136">
                                <Link className="txt2" to="/">
                                    Back to Home
                                    <i className="fa fa-long-arrow-right m-l-5" aria-hidden="true"></i>
                                </Link>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}
