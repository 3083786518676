import React from 'react'

import logo from "../../assets/images/bouclier.png";

export default function SidebarToogle({ open, handleOpen }) {
  return (
    <div className='logo-container sidebar-toogle'>
        <h6 style={{ color : "white" }}>
            <img src={logo} alt="Avatar" />
            <span>Admin</span>
        </h6>
        <button className='menu-bars' type='button' onClick={() => handleOpen(!open)}>
            <i className={`fa fa-${open ? "times" : "bars"}`}></i>
        </button>
    </div>
  )
}
