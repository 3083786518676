import React, { Component } from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import Swal from 'sweetalert2';
import { addExperience, editExperience } from '../../../requests/experiences';

const INITIALSTATE = {
    annee : "", company : "", job: "", description : "",
};

export default class ExperiencesModal extends Component {
    constructor(props) {
        super(props);

        this.state = INITIALSTATE;

        this.handleInput = this.handleInput.bind(this);
        this.add = this.add.bind(this);
    }

    handleInput(e){
        const target = e.currentTarget;

        this.setState({
            [target.name] : target.value
        })
    }

    async add(){
        let send;
        if(this.props.current){
            send = await editExperience(this.state);
        }else{
            this.setState(INITIALSTATE);
            send = await addExperience(this.state);
        }
        if (send) {
            this.props.getExps();
            if (send.error) {
                Swal.fire({
                    title: 'Erreur!',
                    text: 'Echec de l\'enregistrement',
                    icon: 'error',
                    confirmButtonText: 'Fermer',
                })
            }
            else{
                Swal.fire({
                    text: 'Requête éffectué',
                    icon: 'success',
                    confirmButtonText: 'Fermer'
                })
            }
        }
        this.props.handleClose();
    }

    componentDidUpdate(prevProps){
        if (prevProps.current !== this.props.current) {
            if (this.props.current) {
                this.setState(this.props.current);
            }
            else{
                this.setState(INITIALSTATE);
            }
        }
    }

    render() {
        const { show, handleClose, current } = this.props;
        return (
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Compétence</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={this.add}>
                        <Form.Group className="mb-3" controlId="Form.ControlInput1">
                            <Form.Label>Année</Form.Label>
                            <Form.Control type="number" max={new Date().getFullYear()} name='annee' placeholder="2021" onChange={this.handleInput} value={this.state.annee} />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="Form.ControlInput2">
                            <Form.Label>Poste</Form.Label>
                            <Form.Control type="text" name='job' placeholder="Poste" onChange={this.handleInput} value={this.state.job} />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="Form.ControlInput3">
                            <Form.Label>Entreprise</Form.Label>
                            <Form.Control type="text" name='company' placeholder="Entreprise" onChange={this.handleInput} value={this.state.company} />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="Form.ControlTextarea1">
                            <Form.Label>Description</Form.Label>
                            <Form.Control as="textarea" name='description' rows={3} onChange={this.handleInput} value={this.state.description} />
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Annuler
                    </Button>
                    <Button variant="info" onClick={this.add}>
                        { current?"Modifier" :"Ajouter"}
                    </Button>
                </Modal.Footer>
            </Modal>
        )
    }
}
