import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';
import Admin from './views/Admin/Admin';
import Client from './views/App';
import Login from './views/Login';

import { routes } from './routes/routes';

import './App.css';

import "./assets/scss/main.scss";
import useAuth from './hooks/useAuth';

function App() {
  const auth = useAuth();

  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path='/login' element={auth.signin ? <Navigate replace to='/admin' /> : <Login />} />
          <Route path='/admin' element={auth.signin ? <Admin /> : <Navigate replace to='/login' />}>
            {
              routes.map((route, i) => (
                <Route
                    key={i}
                    path={route.path}
                    element={<route.component />}
                />
              ))
            }
          </Route>
          <Route path='/' element={<Client />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
