import React, { Fragment, useState } from "react";
import { NavLink } from "react-router-dom";

export default function Accordion({ link, icon, title, children }){
    const [open, setopen] = useState(false);
    const [maxHeight, setmaxHeight] = useState(0);

    const handleOpen = e => {
        e.preventDefault();

        if (open){
            setmaxHeight(0);
        } else {
            const content = e.currentTarget.nextElementSibling;
            setmaxHeight(content.scrollHeight + "px");
        }
        setopen(!open);
    }

    return <Fragment>
        <NavLink 
            to={link} className="d-flex accordion-btn" 
            style={{ width : "100%", justifyContent : "space-between" }}
            onClick={handleOpen}    
        >
            <span>
                <i className={`link-icon ${icon}`}></i>
                <span className='link-title'>{ title }</span>
            </span>
            <span className={`chevron-button ${open ? "rotate" : ""}`}>
                <i className={`fa fa-chevron-down`}></i>
            </span>
        </NavLink>
        <div className='accordion' style={{ maxHeight : maxHeight }}>
            { children }
        </div>
    </Fragment>
}