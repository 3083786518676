import { createContext, useContext, useState } from 'react';
import Swal from 'sweetalert2';
import { postData } from '../api/request';

const authContext = createContext();

export function AuthProvider({ children }) {
    const auth = useProvideAuth();
    return <authContext.Provider value={auth}>{children}</authContext.Provider>
}

export default function useAuth() {
    return useContext(authContext);
}

function useProvideAuth() {
    const local_auth = localStorage.getItem("_token");
    const key = local_auth ?? false;
    const [auth, setAuth] = useState(key);
    return {
        signin: auth,
        verifyToken:function () {
            if (auth) {
                return true;
            }
        },
        login : async function(email, password){
            if (email && password) {
                postData({
                    url : '/login',
                    data : { email, password },
                    callback : function (response) {
                        if(response && response.token){
                            localStorage.setItem("_token", response.token);
                            setAuth(true);
                        }
                        else {
                            Swal.fire(
                                'Erreur',
                                'Erreur de connexion',
                                'error',
                            )
                        }
                    }
                })
            }

            return await true
        },
        logout : function(){
            postData({
                url : '/logout',
                callback : function (response) {
                    if(response){
                        localStorage.removeItem("_token");
                        setAuth(false);
                    }
                }
            })
        },
    }
}