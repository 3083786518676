import React, { Fragment, useEffect, useState } from 'react'
import Swal from 'sweetalert2';
import { getExperiences, removeExperience } from '../../../requests/experiences';
import ExperiencesModal from '../modals/experiences';
import TableComponent from './table';

const COLUMNS = [
    { title : "Annee", field : "annee" },
    { title : "Poste", field : "job" },
    { title : "Entreprise", field : "company" },
    { title : "Description", field : "description" },
];

export default function ExperiencesView() {
    const [experiences, setexperiences] = useState([]);
    const [openModal, setopenModal] = useState(false);
    const [current, setcurrent] = useState(null);

    const handleOpenModal = () => setopenModal(true);
    const handleCloseModal = () => {
        setcurrent(null);
        setopenModal(false);
    }

    useEffect(() => {
        getExps();
    }, []);

    const getExps = () => getExperiences().then(res=>setexperiences(res));

    const removeExp = async (id) => {
        const r = removeExperience(id);
        if (r) {
            getExps();
            Swal.fire({
                text: 'Élément supprimé',
                icon: 'success',
                confirmButtonText: 'Fermer',
            })
        }
    }
    
    const editExp = (data) => {
        setcurrent(data);
        handleOpenModal();
    }

    return (
        <Fragment>
            <ExperiencesModal 
                show={openModal}
                handleClose={handleCloseModal}
                current={current}
                getExps={getExps}
            />
            <TableComponent 
                handleOpen={handleOpenModal}
                columns={COLUMNS}
                datas={experiences}
                id={"experiences"}
                title="Experiences"
                remove={removeExp}
                edit={editExp}
            />
        </Fragment>
    )
}
