import React, { useEffect, useState } from 'react';
import { Card, Button, Table } from 'react-bootstrap';
import { getProjects, removeProject } from '../../../requests/works';
import Swal from 'sweetalert2';
import { propTypes } from 'react-bootstrap/esm/Image';
import WorkModal from '../modals/works';
import { file_baseurl } from '../../../api/env';

const COLUMNS = [
    { title : "Image", field : "image" },
    { title : "Titre", field : "title" },
    { title : "Lien", field : "link" },
];

const TableComponent = function ({datas, edit, onRemove}) {
    return <Table responsive striped>
        <thead>
            <tr>
                <th>#</th>
                {COLUMNS.map((column, index) => (
                    <th key={index}>{column.title}</th>
                ))}
                <th>Actions</th>
            </tr>
        </thead>
        <tbody>
        {
            datas.map((data, index) => (
                <tr key={index}>
                    <td>{index + 1}</td>
                    {COLUMNS.map((column, index) => {
                        if(column.field==="image"){
                            return <td key={index}>
                                <img src={file_baseurl+data[column.field]} alt="" style={{
                                    maxWidth: "250px",
                                    maxHeight:"100px",
                                    objectFit:"contain"
                                }} />
                            </td>
                        }
                        else{
                            return <td key={index}>{data[column.field]}</td>
                        }
                    })}
                    <td style={{ width : "175px" }}>
                        <Button variant="info" onClick={() => edit(data)}><i className='fa fa-edit'></i></Button>{' '}
                        <Button variant="danger" onClick={() => onRemove(data.id)}><i className='fa fa-trash'></i></Button>
                    </td>
                </tr>
            ))
        }
        </tbody>
    </Table>
}

export default function Projects() {
    const [works, setworks] = useState([]);
    const [openModal, setopenModal] = useState(false);
    const [current, setcurrent] = useState(null);

    const handleOpenModal = () => setopenModal(true);
    const handleCloseModal = () => {
        setcurrent(null);
        setopenModal(false);
    }

    useEffect(() => {
        getWorks();
    }, []);

    const getWorks = () => getProjects().then(res=>res&&setworks(res));

    const removeWork = async (id) => {
        const r = removeProject(id);
        if (r) {
            Swal.fire({
                text: 'Élément supprimé',
                icon: 'success',
                confirmButtonText: 'Fermer',
            })
            getWorks();
        }
    }
    
    const editWork = (data) => {
        setcurrent(data);
        handleOpenModal();
    }

    return (
        <section className='mb-4'>
            <Card className='p-3'>
                <div className='container d-flex mb-3' style={{ justifyContent : "space-between", alignItems : "center" }}>
                    <h6>Projets</h6>
                    <Button variant="primary" onClick={handleOpenModal}>Ajouter <i className='fa fa-plus'></i></Button>
                </div>
                <div className='container'>
                    <WorkModal
                        show={openModal}
                        handleClose={handleCloseModal}
                        current={current}
                        getExps={getWorks}
                    />
                    <TableComponent 
                        datas={works}
                        edit={editWork}
                        onRemove={removeWork}
                    />
                </div>
            </Card>
        </section>
    )
}

TableComponent.propTypes = {
    datas: propTypes.array
};