import { getData, postData } from "../api/request";

export async function getCompetences(){
    let result = [];

    await getData({
        url : "/competences",
        callback : response=>{
            if (response) {
                result = response
            }
        }
    })

    return result;
}

export async function addCompetence({icon, title, description}){
    let result = [];

    await postData({
        url : "/competences",
        data: {icon, title, description},
        callback : response=>{
            result = response;
        }
    })

    return result;
}

export async function editCompetence({id, icon, title, description}){
    let result = [];

    await postData({
        method : 'put',
        url : `/competences/${id}`,
        data: {icon, title, description},
        callback : response=>{
            result = response;
        }
    })

    return result;
}

export async function removeCompetence(id){
    let result = [];

    await postData({
        method : 'delete',
        url : `/competences/${id}`,
        data: {id},
        callback : response=>{
            result = response;
        }
    })

    return result;
}