import React, { Fragment, useEffect, useState } from 'react';
import { getCompetences, removeCompetence } from '../../../requests/competences';
import SkillsModal from '../modals/skills';
import Swal from 'sweetalert2';
import TableComponent from './table';

const COLUMNS = [
    { title : "Icone (font-awesome)", field : "icon" },
    { title : "Titre", field : "title" },
    { title : "Description", field : "description" },
];

export default function SkillsView() {
    const [skills, setskills] = useState([]);
    const [openModal, setopenModal] = useState(false);
    const [current, setcurrent] = useState(null);

    const handleOpenModal = () => setopenModal(true);
    const handleCloseModal = () => {
        setcurrent(null);
        setopenModal(false);
    }

    useEffect(() => {
        getSkills();
    }, []);

    const getSkills = () => getCompetences().then(res=>setskills(res));

    const removeSkill = async (id) => {
        const r = removeCompetence(id);
        if (r) {
            Swal.fire({
                text: 'Élément supprimé',
                icon: 'success',
                confirmButtonText: 'Fermer',
            })
            getSkills();
        }
    }
    
    const editSkill = (data) => {
        setcurrent(data);
        handleOpenModal();
    }

    return (
        <Fragment>
            <SkillsModal 
                show={openModal}
                handleClose={handleCloseModal}
                current={current}
                getSkills={getSkills}
            />
            <TableComponent 
                handleOpen={handleOpenModal}
                columns={COLUMNS}
                datas={skills}
                id={"competences"}
                title="Compétences"
                remove={removeSkill}
                edit={editSkill}
            />
        </Fragment>
    )
}