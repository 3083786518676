import React from 'react'

export default function Footer() {
    return (
        <footer className="footer">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12 col-12">
                        <p className="copyright-text text-center">Copyright &copy; 2022 Sylla Miran. All rights reserved</p>
                    </div>

                </div>
            </div>
        </footer>
    )
}
