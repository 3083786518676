import React, { useEffect, useState } from 'react';

import lines from "../../assets/images/lines.svg";
import { AppearingContainer } from 'react-appear-on-scroll';
import { getExperiences } from '../../requests/experiences';
import { getEducations } from '../../requests/educations';

export default function Experiences() {
    const [exps, setexps] = useState([]);
    const [educations, seteducations] = useState([]);

    useEffect(() => {
        getExperiences().then(result=>setexps(result));
        getEducations().then(result=>seteducations(result));
    }, [])
    

    return (
        <section className="resume container d-lg-flex justify-content-center align-items-center" id="resume">
            <div className="container">
                <div className="row resume-padding" style={{ padding : "3rem" }}>

                    <div className="col-lg-6 col-12">
                        <h2>Experiences</h2>
                        <img src={lines} className="img-lines" alt="lines" />
                        <div className="timeline">
                            {
                                exps.map((item, index) => (<div className="timeline-wrapper" key={index}>
                                    <div className="timeline-yr">
                                        <span>{item.annee}</span>
                                    </div>
                                    <div className="timeline-info">
                                        <h3><span>{item.job}</span><small>{item.company}</small></h3>
                                        <p>{item.description}</p>
                                    </div>
                                </div>))
                            }

                        </div>
                    </div>

                    <div className="col-lg-6 col-12">
                        <h2 className="mobile-mt-2">Educations</h2>
                        <img src={lines} className="img-lines" alt="lines" />
                        <div className="timeline">
                            {
                                educations.map((item, index) => (<div className="timeline-wrapper" key={index}>
                                    <div className="timeline-yr">
                                        <span>{item.annee}</span>
                                    </div>
                                    <div className="timeline-info">
                                        <h3><span>{item.filiere}</span><small>{item.school}</small></h3>
                                        <p>{item.description}</p>
                                    </div>
                                </div>))
                            }

                        </div>
                    </div>
                    
                </div>
            </div>
        </section>
    )
}
