import React, { useState } from 'react';

import logo from "../../assets/images/portfolio-professionnel.png";

export default function Header() {
    const [open, setopen] = useState(false);
    const toogleMenu = (toOpen = !open) => setopen(toOpen);

    return (
        <header id="masthead" className="site-header">
            <nav className="navbar navbar-expand-lg">
                <div className="container-fluid container">
                    <a className="navbar-brand" href="/#">
                        <img src={logo} alt="Logo" height="24" className="d-inline-block align-text-top" />
                        <span><strong>P</strong>ortfolio</span>
                    </a>
                    <div className="menu-links" style={{ marginLeft : "auto" }}>
                        <button className="menu-btn btn btn-link text-light" type='button' onClick={() => toogleMenu()}>
                            <i className='fa fa-bars'></i>
                        </button>
                        <ul className={`navbar-nav ${open ? "open" : ""}`}>
                            <li className="nav-item active">
                                <a className="nav-link" aria-current="page" href="#about" onClick={() => toogleMenu(false)}>About</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" href="#skills" onClick={() => toogleMenu(false)}>Skills</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" href="#resume" onClick={() => toogleMenu(false)}>Experiences</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" href="#portfolio" onClick={() => toogleMenu(false)}>Projects</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
        </header>
    )
}
