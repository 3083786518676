export const links = [
    {
        title : "Navigation",
        type : "section",
        children : [
            {
                title : "Modifier",
                icon : "fa fa-user",
                type : "link",
                path : "",
            },
        ],
    },
];